html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: #0069ed;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
    transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:active {
    transform: scale(0.99);
}

/* styles */

.inline-block {
    display: inline-block;
}

.hole {
    height: .5rem;
    width: .5rem;
    margin: .25rem;
    border-radius: .25rem;
}

.text-red {
    color: red;
}

.text-blue {
    color: blue;
}


.bg-red {
    background-color: red;
}

.bg-blue {
    background-color: blue;
}

.bg-gray {
    background-color: #999999;
}

.p-2 {
    padding: .5rem;
}

.m-2 {
    margin: .5rem;
}

.pb-4 {
    padding-bottom: 1rem;
}

.mr-4 {
    margin-right: 1rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.cribbage-board {
    border: 1px solid #666;
    padding: 1rem;
    display: inline-block;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.text-white {
    color: white;
}

.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.w-3/6 {
    width: 50%;
}

.rounded-sm {
    border-radius: 0.125rem;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-center {
    text-align: center;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}